import React from "react";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "@mui/material/styles";

const Root = styled(`footer`)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6),
  borderTopColor: theme.palette.grey[100],
  borderTopWidth: "1px",
  borderTopStyle: "solid",
}));

interface CopyrightProps {
  siteUrl: string;
}

const Copyright: React.FC<CopyrightProps> = ({ siteUrl }) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © `}
      <MuiLink color="inherit" href={siteUrl}>
        Patrick Cameron
      </MuiLink>
      {` `}
      {new Date().getFullYear()}
      {`.`}
    </Typography>
  );
};

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <Root>
      <Typography variant="h6" align="center" gutterBottom>
        pcameron.com
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        Software Engineer passionate about writing high performant C# code
      </Typography>
      <Copyright siteUrl={data.site.siteMetadata.siteUrl} />
    </Root>
  );
};

export default Footer;
