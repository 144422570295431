import { Avatar, styled } from "@mui/material";
import React from "react";

import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";

const AvatarText = styled(`span`)(({ theme }) => ({
  position: "absolute",
  fontSize: "0.7rem",
  top: "0.8rem",
}));

const MyAvatar: React.FunctionComponent = () => {
  return (
    <Avatar sx={{ marginRight: 2 }}>
      <AvatarText>PC</AvatarText>
      <DesktopWindowsIcon></DesktopWindowsIcon>
    </Avatar>
  );
};

export default MyAvatar;
