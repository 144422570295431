import { Link } from "gatsby-theme-material-ui";
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyAvatar from "./MyAvatar";

const StyledLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.primary.contrastText,
}));

interface HeaderProps {
  siteTitle: string;
}

const Header: React.FunctionComponent<HeaderProps> = ({ siteTitle }) => {
  return (
    <header>
      <AppBar position="static">
        <Toolbar>
          <MyAvatar />
          <Typography>{siteTitle}</Typography>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/projects/">Projects</StyledLink>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
